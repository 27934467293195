<script>
    import Header from './lib/Header.svelte';
    import Row from './lib/Row.svelte';
    import Toggle from './lib/Toggle.svelte';
    import { countries } from './lib/countries.js';
    import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
    import confetti from 'canvas-confetti';
    import Select from 'svelte-select';
    import hotkeys from 'hotkeys-js';
    import dayjs from 'dayjs';
    import timezone from 'dayjs/plugin/timezone';
    import utc from 'dayjs/plugin/utc';
    import advancedFormat from 'dayjs/plugin/advancedFormat';
    dayjs.extend(timezone);
    dayjs.extend(utc);
    dayjs.extend(advancedFormat)

    let gamedata = {
            daily: [

            ],
            stats: {
                'streak': {
                    'current': 0,
                    'top': 0
                },
                'scores': {
                    'x': 0,
                    '6': 0,
                    '5': 0,
                    '4': 0,
                    '3': 0,
                    '2': 0,
                    '1': 0,
                },
                'total': {
                    'total': 0,
                    'wins': 0,
                    'loses': 0
                }
            },
            today: {},
            settings: {
                'tz': '',
                'localtz': '',
                'cycle': 'local',
                'rainbowPill': true,
                'confetti': true,
                'combineStats': true,
                'leaderboard': true
            },
            day: 0
        },
        leaderboard = {},
        gamedataone,
        gamedatatwo,
        gamedatathree,
        gamedatafour,
        todayone,
        todaytwo,
        todaythree,
        todayfour,
        loaded = false,
        input = '',
        focus = 'tutorial',
        copied = false,
        colors = ['red-500', 'blue-500', 'green-500', 'yellow-500', 'green-500', 'blue-500', 'red-500'];

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    onMount(async () => {
        loaded = false;
        if (window.localStorage.getItem('flaggle-played-five') == 'true') {
            gamedata = JSON.parse(window.localStorage.getItem('flaggle-gamedata-five'));
            focus = 'game';

            // if (gamedata.settings.leaderboard != true && gamedata.settings.leaderboard != false) {
            //     focus = 'addedleaderboard';
            // };
        } else {
            if ((window.localStorage.getItem('flaggle-played') == 'true' || window.localStorage.getItem('flaggle-played-two') == 'true' || window.localStorage.getItem('flaggle-played-three') == 'true' || window.localStorage.getItem('flaggle-played-four') == 'true') && !window.localStorage.getItem('flaggle-played-five')) {
                focus = 'seasonfive';
            };
            
            window.localStorage.setItem('flaggle-gamedata-five', JSON.stringify(gamedata));
            window.localStorage.setItem('flaggle-played-five', 'true');
        };

        if (params.resetData == 'true') {
            window.localStorage.removeItem('flaggle-played-five');
            window.location.search = '';
        }

        // countries = await fetch('/assets/countries.json').then(res => {
        //     return res.json()
        // });
        window.countries = countries;
        // countries.array.forEach(async c => {
        //     c.FlagE = 'https://countryflagsapi.com/svg/' + c.CountryCodes.iso2;
        //     c.Flag = '/assets/img/flags/' + c.CountryCodes.iso2 + '.svg';
        //     c.Filter = c.Name.toLowerCase();
        // });

        if (params.importdata) {
            let data = JSON.parse(decodeURIComponent(params.importdata));
            Object.keys(data.played).forEach(a => {
                if (gamedata.daily.filter(b => b.day == parseInt(a) + 1).length == 0) {
                    gamedata.daily = [...gamedata.daily, {
                        'day': parseInt(a) + 1,
                        'correct': countries[a],
                        'guesses': [],
                        'share': {
                            'rows': [],
                            'str': ''
                        },
                        'result': data.played[a].result == 'raise' ? 'Won' : 'Lost'
                    }];
                }
            });
            focus = 'imported';
        };

        gamedata.settings.localtz = await dayjs.tz.guess();

        await pickDay(dayjs().diff(dayjs('2024-09-22'), 'day') + 1);

        plausible('Loaded Game');

        loaded = true;

        if (gamedata.settings.leaderboard == true) {
            leaderboard = await fetch('https://api.flaggle.izmichael.com/stats', {
            // leaderboard = await fetch('http://localhost:4851/stats', {
                method: 'GET',
                headers: {
                    'Authorization': '7RjEa5jXVK4L56v2xBa5jFY2GsF3unMQ',
                    'Content-Type': 'application/json',
                    'checkday': gamedata.day
                }
            }).then(res => { plausible('Loaded Leaderboard'); return res.json(); });
        };
    });

    async function pickDay(i) {
        gamedata.day = i;
        if (gamedata.daily.filter(a => a.day == gamedata.day).length > 0) {
            gamedata.today = gamedata.daily.filter(a => a.day == gamedata.day)[0];
        } else {
            gamedata.daily = [...gamedata.daily, {
                'day': gamedata.day,
                'correct': countries[gamedata.day - 1],
                'guesses': [],
                'share': {
                    'rows': [],
                    'str': ''
                },
                'result': 'Undecided'
            }];
            gamedata.daily.sort((a, b) => a.day > b.day ? 1 : -1)
            gamedata.today = gamedata.daily.filter(a => a.day == gamedata.day)[0];
        }

        if (window.localStorage.getItem('flaggle-played') == 'true') {
            gamedataone = JSON.parse(window.localStorage.getItem('flaggle-gamedata'));
            console.log(gamedataone);
            let daynowone = (gamedataone?.daily ?? []).filter(a => a.correct?.Filter == gamedata.today.correct.Filter);
            console.log(daynowone);
            if (daynowone.length == 1) todayone = daynowone[0];
        };

        if (window.localStorage.getItem('flaggle-played-two') == 'true') {
            gamedatatwo = JSON.parse(window.localStorage.getItem('flaggle-gamedata-two'));
            console.log(gamedatatwo);
            let daynowtwo = (gamedatatwo?.daily ?? []).filter(a => a.correct?.Filter == gamedata.today.correct.Filter);
            console.log(daynowtwo);
            if (daynowtwo.length == 1) todaytwo = daynowtwo[0];
        };

        if (window.localStorage.getItem('flaggle-played-three') == 'true') {
            gamedatathree = JSON.parse(window.localStorage.getItem('flaggle-gamedata-three'));
            console.log(gamedatathree);
            let daynowthree = (gamedatathree?.daily ?? []).filter(a => a.correct?.Filter == gamedata.today.correct.Filter);
            console.log(daynowthree);
            if (daynowthree.length == 1) todaythree = daynowthree[0];
        };

        if (window.localStorage.getItem('flaggle-played-four') == 'true') {
            gamedatafour = JSON.parse(window.localStorage.getItem('flaggle-gamedata-four'));
            console.log(gamedatafour);
            let daynowfour = (gamedatafour?.daily ?? []).filter(a => a.correct?.Filter == gamedata.today.correct.Filter);
            console.log(daynowfour);
            if (daynowfour.length == 1) todayfour = daynowfour[0];
        };
        return true;
    }

    $: if (loaded == true) {
        window.localStorage.setItem('flaggle-gamedata-five', JSON.stringify(gamedata));
    };
    $: if (loaded == true) {
        gamedata.today = gamedata.daily.filter(a => a.day == gamedata.day)[0];
    };
    $: if (loaded == true) {
        window.history.pushState({}, '', focus + window.location.search);
    };

    async function submitGuess() {
        let match = countries.filter(a => a.Filter == input.value);
        if (match.length < 1) {
            console.log('Invalid Input');
            input = '';
            return;
        };

        gamedata.today.guesses = [...gamedata.today.guesses, {'country': match[0], 'distance': 0, 'percentage': 0}];
        if (match[0].Filter == gamedata.today.correct.Filter) {
            // Win
            gamedata.today.result = 'Won';
            gamedata.stats.streak.current ++;
            if (gamedata.stats.streak.current > gamedata.stats.streak.top) gamedata.stats.streak.top = gamedata.stats.streak.current;
            gamedata.stats.scores[gamedata.today.guesses.length] ++;
            gamedata.stats.total.wins ++;
            gamedata.stats.total.total ++;

            if (gamedata.settings.leaderboard == true) {
                plausible('Submitted to Leaderboard');
                await fetch('https://api.flaggle.izmichael.com/submit', {
                // await fetch('http://localhost:4851/submit', {
                    method: 'POST',
                    headers: {
                        'Authorization': '7RjEa5jXVK4L56v2xBa5jFY2GsF3unMQ',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(gamedata.today)
                }).then(res => console.log('Logged Result', res.json()));
            };

            plausible('Win');

            if (gamedata.settings.confetti == true) success();
        } else if (gamedata.today.guesses.length >= 6) {
            // Lose
            gamedata.today.result = 'Lost';
            gamedata.stats.streak.current = 0;
            gamedata.stats.scores['x'] ++;
            gamedata.stats.total.loses ++;
            gamedata.stats.total.total ++;

            if (gamedata.settings.leaderboard == true) {
                plausible('Submitted to Leaderboard');
                await fetch('https://api.flaggle.izmichael.com/submit', {
                // await fetch('http://localhost:4851/submit', {
                    method: 'POST',
                    headers: {
                        'Authorization': '7RjEa5jXVK4L56v2xBa5jFY2GsF3unMQ',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(gamedata.today)
                }).then(res => console.log('Logged Result', res.json()));
            };

            plausible('Lose');
        };
        
        input = '';
    };

    hotkeys('ctrl+enter', (event, handler) => submitGuess());
    
    hotkeys.filter = () => {
        return true;
    };
    
    let timeToNext = '00:00:00', nextAt = dayjs();
    setInterval(() => {
        timeToNext = dayjs.duration(dayjs().add(24, 'h').set('h', 0).set('m', 0).set('s', 0).set('ms', 0).diff(dayjs())).format('HH:mm:ss');
        nextAt = dayjs(dayjs().add(24, 'h').set('h', 0).set('m', 0).set('s', 0).set('ms', 0));
    }, 500);

    function sleep(s) {
        return new Promise(resolve => setTimeout(resolve, (s * 1000)));
    };

    function success() {
        confetti({
            particleCount: 500,
            spread: 75,
            origin: {
                x: 0.5,
                y: 0.07
            },
            angle: 270,
            ticks: 100
        });
    };

    function getEmoji(c) {
        return String.fromCodePoint(...[...c.toUpperCase()].map(x => 0x1f1a5 + x.charCodeAt()))
    };

    async function share(type = 'standard') {
        gamedata.today.share.rows = [];
        for (const guess of gamedata.today.guesses) {
            let row = `${getEmoji(guess.country.CountryCodes.iso2)}  <<  ${Math.round(guess.distance)} km  >>  ${getEmoji(gamedata.today.correct.CountryCodes.iso2)}`;
            // let lng = Math.floor(guess.percentage / 20);
            // console.log(lng, Math.floor(guess.percentage / 20), guess.percentage)
            // for (let i of Array(lng)) str.push('🟩');
            // for (let i of Array(5 - lng)) str.push('🟨');

            gamedata.today.share.rows = [...gamedata.today.share.rows, row]
        };
        if (type == 'reddit') {
            gamedata.today.share.str = `Season 5 - Streak: ${gamedata.stats.streak.current}\n\n${gamedata.today.share.rows.join('\n')}\n\nhttps://flaggle.izmichael.com`;
        } else {
            gamedata.today.share.str = `#FLAGGLE S5 ${gamedata.today.day} - Streak: ${gamedata.stats.streak.current}\n\n${gamedata.today.share.rows.join('\n')}\n\nhttps://flaggle.izmichael.com`;
        }
        return gamedata.today.share.str;
    }

    function fallbackCopy(text) {
        var textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copied to clipboard.');
        } catch (err) {
            console.log('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    function copy(text) {
        if (!navigator.clipboard) {
            fallbackCopy(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Copied to clipboard.');
        }, function (err) {
            fallbackCopy(text);
        });
    }
</script>

<div id="app-wrapper" class="bg-slate-200 flex flex-row items-start justify-center w-full max-h-screen overflow-hidden">
    {#if loaded == true && gamedata.today}
    <div id="app-container" class="lg:w-2/5 flex flex-col items-center justify-start w-full h-full bg-white">
        <Header {gamedata} {timeToNext} bind:focus rainbow={gamedata.settings.rainbowPill == true && gamedata.today.result == 'Won'} {leaderboard}></Header>

        <div id="game" class="lg:px-10 relative flex flex-col items-center justify-start flex-1 w-full max-h-full gap-2 px-5 pb-5 mt-5 overflow-auto">
            <img src={gamedata.today.correct.Flag} alt="Mystery Flag #{gamedata.day + 1}" draggable="false" class="h-40 mx-auto border-2 border-gray-200 rounded-md" on:contextmenu|preventDefault={() => { return false }}>
            <h1 class="w-full mb-4 text-xl text-center {gamedata.today.result == 'Undecided' ? 'invisible' : ''}" transition:fade>{gamedata.today.correct.Name}</h1>

            {#each gamedata.today.guesses as guess, i}
                <Row country={guess.country} correct={gamedata.today.correct} bind:distance={gamedata.today.guesses[i].distance} bind:percentage={gamedata.today.guesses[i].percentage}></Row>
            {/each}
            {#if gamedata.today.guesses.length < 6 && gamedata.today.result == 'Undecided'}
                <div class="flex flex-row items-center justify-between w-full rounded-full max-w-[91.666667vw] h-9 shadow-elevate">
                    <Select placeholder="Country or Territory" items={[...countries].sort((a, b) => a.Filter > b.Filter ? 1 : -1).map(a => {return {
                        value: a.Filter,
                        label: a.Name
                    }})} bind:value={input} containerStyles="border-radius: 9999px; height: 2.25rem; max-width: 60%; white-space: nowrap; flex: 1 1 0%; border: none; cursor: text;" inputStyles="border-radius: 9999px; height: 2.25rem; flex: 1 1 0%; cursor: text;"></Select>
                    <button on:click={() => submitGuess()} class="flex-shrink-0 w-2/5 h-full p-1 px-4 font-bold bg-blue-300 rounded-full">Guess ({gamedata.today.guesses.length + 1}/6)</button>
                </div>
                <p class="w-full -mt-1 text-sm italic text-right text-gray-600">Press Ctrl + Enter to guess</p>
            {:else}
                <!-- <button transition:fade on:click={async () => { copy(await share()); copied = true; await sleep(3); copied = false; }} class="h-9 shadow-elevate flex-none w-full p-1 px-4 font-bold text-white bg-yellow-500 rounded-full">Copy my Results</button> -->
                <!-- <p class="text-gray-600 transition-all duration-300 ease-in-out overflow-hidden {copied == true ? 'h-6' : 'h-0 -mt-2'}">✅ Successfully Copied to Clipboard</p> -->
                <button transition:fade on:click={async () => focus = 'stats'} class="w-full overflow-hidden rounded-full flex-none {gamedata.today.result == 'Won' ? 'bg-green-500' : 'bg-red-500'} font-bold h-9 text-center text-white">You {gamedata.today.result}! Come back in {timeToNext}.</button>
                {#if gamedataone && todayone?.result == 'Won'}
                <button transition:fade on:click={async () => focus = 'stats'} class="h-9 flex-none w-full overflow-hidden font-bold text-center text-white bg-green-500 rounded-full">In Season 1, you got this in {todayone.guesses.length} guess{todayone.guesses.length == 1 ? '' : 'es'}.</button>
                {:else if gamedataone && todayone?.result == 'Lost'}
                <button transition:fade on:click={async () => focus = 'stats'} class="h-9 flex-none w-full overflow-hidden font-bold text-center text-white bg-red-500 rounded-full">In Season 1, you didn't get this.</button>
                {/if}
                {#if gamedatatwo && todaytwo?.result == 'Won'}
                <button transition:fade on:click={async () => focus = 'stats'} class="h-9 flex-none w-full overflow-hidden font-bold text-center text-white bg-green-500 rounded-full">In Season 2, you got this in {todaytwo.guesses.length} guess{todaytwo.guesses.length == 1 ? '' : 'es'}.</button>
                {:else if gamedatatwo && todaytwo?.result == 'Lost'}
                <button transition:fade on:click={async () => focus = 'stats'} class="h-9 flex-none w-full overflow-hidden font-bold text-center text-white bg-red-500 rounded-full">In Season 2, you didn't get this.</button>
                {/if}
                {#if gamedatathree && todaythree?.result == 'Won'}
                <button transition:fade on:click={async () => focus = 'stats'} class="h-9 flex-none w-full overflow-hidden font-bold text-center text-white bg-green-500 rounded-full">In Season 3, you got this in {todaythree.guesses.length} guess{todaythree.guesses.length == 1 ? '' : 'es'}.</button>
                {:else if gamedatathree && todaythree?.result == 'Lost'}
                <button transition:fade on:click={async () => focus = 'stats'} class="h-9 flex-none w-full overflow-hidden font-bold text-center text-white bg-red-500 rounded-full">In Season 3, you didn't get this.</button>
                {/if}
                {#if gamedatafour && todayfour?.result == 'Won'}
                <button transition:fade on:click={async () => focus = 'stats'} class="h-9 flex-none w-full overflow-hidden font-bold text-center text-white bg-green-500 rounded-full">In Season 4, you got this in {todayfour.guesses.length} guess{todayfour.guesses.length == 1 ? '' : 'es'}.</button>
                {:else if gamedatafour && todayfour?.result == 'Lost'}
                <button transition:fade on:click={async () => focus = 'stats'} class="h-9 flex-none w-full overflow-hidden font-bold text-center text-white bg-red-500 rounded-full">In Season 4, you didn't get this.</button>
                {/if}
                <!-- <button transition:fade on:click={async () => window.location = 'https://www.reddit.com/r/flaggle/submit?title=My%20Score%3A%20Flaggle%20%23' + gamedata.day + ' - ' + dayjs().format('dddd, Do MMMM YYYY') + '&text=' + encodeURIComponent(await share('reddit'))} class="w-full flex-none p-1 px-4 font-bold mt-5 text-white bg-[#FF5700] rounded-full h-9 shadow-elevate">Share my Results on r/Flaggle</button>
                <button transition:fade on:click={async () => window.location = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(await share())} class="w-full flex-none p-1 px-4 font-bold text-white bg-[#1D9BF0] rounded-full h-9 shadow-elevate">Tweet my Results</button> -->

                <div class="h-9 shadow-elevate flex flex-row items-center justify-between flex-none w-full overflow-hidden rounded-full">
                    <button class="flex items-center justify-center flex-1 h-full p-1 bg-yellow-500" on:click={async () => { copy(await share()); copied = true; await sleep(3); copied = false; }}>
                        <img src="/assets/img/icons/copy.svg" class="aspect-square invert h-full" alt="Copy Button">
                    </button>
                    <button class="h-full flex-1 bg-[#1D9BF0] p-1 flex justify-center items-center" on:click={async () => window.location = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(await share())}>
                        <img src="/assets/img/icons/twitter.svg" class="aspect-square invert h-full" alt="Tweet Button">
                    </button>
                    <button class="h-full flex-1 bg-[#FF5700] p-1 flex justify-center items-center" on:click={async () => window.location = 'https://www.reddit.com/r/flaggle/submit?title=My%20Score%3A%20Flaggle%20%23' + gamedata.day + ' - ' + dayjs().format('dddd, Do MMMM YYYY') + '&text=' + encodeURIComponent(await share('reddit'))}>
                        <img src="/assets/img/icons/reddit.svg" class="aspect-square invert h-full" alt="Reddit Button">
                    </button>
                </div>
                <p class="text-gray-600 transition-all duration-300 ease-in-out overflow-hidden {copied == true ? 'h-6' : 'h-0 -mt-2'}">✅ Successfully Copied to Clipboard</p>

                {#if dayjs().diff(dayjs('2024-09-22'), 'day') + 1 > gamedata.day}
                    <button transition:fade on:click={async () => pickDay(gamedata.day + 1)} class="h-9 shadow-elevate flex-none w-full p-1 px-4 mt-5 font-bold text-white bg-green-500 rounded-full">Next Flag →</button>
                {:else if dayjs().diff(dayjs('2024-09-22'), 'day') + 1 == gamedata.day}
                    <button transition:fade on:click={async () => focus = 'archive'} class="h-9 shadow-elevate flex-none w-full p-1 px-4 mt-5 font-bold text-white bg-green-500 rounded-full">← View Previous Days</button>
                {/if}
            {/if}
            <button on:click={async () => window.location = 'https://ko-fi.com/IzMichael'} class="flex-shrink-0 text-white flex flex-row justify-between items-center shadow-elevate font-semibold w-full p-1 px-4 bg-[#ff4646] rounded-full h-16 text-center">
                <img src="/assets/img/icons/heart-white.svg" alt="Heart Icon" class="h-7 w-7 mr-2" />
                Like Flaggle?<br>Consider making a donation!
                <img src="/assets/img/icons/kofi.png" alt="Ko-Fi Icon" class="h-7 w-7 ml-2" />
            </button>
        </div>

        {#if focus != 'game'}
            <div transition:fade class="lg:w-2/5 bg-opacity-40 absolute z-30 flex flex-col items-center justify-center w-full h-screen max-h-screen bg-black">
                <div class="flex flex-col items-center justify-start w-full lg:w-[95%] max-h-screen bg-white lg:rounded-xl lg:drop-shadow-2xl">
                    <div class="w-full p-5 border-b-2 border-gray-500">
                        <button class="icbtn" on:click={() => { if (focus == 'addedleaderboard') gamedata.settings.leaderboard = true; focus = 'game'; }}><img src="/assets/img/icons/close.svg" alt="Close"/></button>
                    </div>
    
                    {#if focus == 'tutorial'}
                        <div id="tutorial" class="flex flex-col items-center justify-center w-full h-full max-h-[75vh] lg:px-5 py-5 px-2 76543">
                            <h1 class="w-full mb-5 text-3xl text-center">Welcome to Flaggle - the flag guessing game!</h1>

                            <div class="lg:px-10 flex flex-col items-center justify-start w-full px-3 overflow-y-auto text-lg text-center">
                                <p>The game will present you with a flag, and your job is to identify the country or territory that the flag belongs to.</p>
                                <img src="/assets/img/Full_Game.png" alt="Full Game">
                                <p>In this example, I have already guessed the U.S, France, Australia, Azerbaijan, and Ireland.</p>
                                <p class="mt-2">To guess, you need to begin to type your guess into the box, as shown below.</p>
                                <img src="/assets/img/UK_Raw_Input.png" alt="Raw Input UK">
                                <p>Once you can see your chosen country, click on the label to confirm your choice. If you do not confirm your choice, you will not be able to guess.</p>
                                <p class="mt-2">Once you have chosen, your input box should look something like this: (Note the 'x' icon, allowing you to clear your selection.)</p>
                                <img src="/assets/img/UK_Input.png" alt="Input UK">
                                <p>Once you have made a selection, simply press 'Guess' to make your guess.</p>
                                <p>A guess will now appear on the board. Let's take a look at our guess:</p>
                                <img src="/assets/img/UK_Row.png" alt="UK Row">
                                <p>On the far left, you can see the flag of your selected country. Even if your guess isn't the correct answer it will show the flag of your guess, just so you know what the flag looks like!</p>
                                <p class="w-full my-2">Next to the flag, you can simply see your guess.</p>
                                <p>On the far right, the distance from your guess to the correct answer is noted. This can help you decide where to guess next.</p>
                                <br>
                                <br>
                                <p>In this case, I have guessed correctly, and so it shows that I have won this round!</p>
                                <p>In this example, these were our guesses: (Note: The United Kingdom is the answer, and so guessing The United Kingdom would result in 0km.)</p>
                                <img src="/assets/img/Game_Win.png" alt="Game after Winning">
                                <p>Once you finish, either by solving or running out of attempts, you will have the opportunity to share your score.</p>
                                <p>Clicking this button will copy a message to your clipboard.</p>
                                <br>
                                <p>Each line of the emoji grid corresponds to a guess.</p>
                                <p>The line will show your guess, the distance between your guess and the correct answer, and the correct answer.</p>
                                <p>Sharing your score on Twitter would look a bit like this.</p>
                                <img src="/assets/img/Twitter_Share.png" alt="Sharing on Twitter">
                                <br>
                                <p>Pressing the '?' button at the top will return you to this help page at any time.</p>
                                <p>Pressing the bar chart next to it will show you your statistics page.</p>
                                <p>If you like Flaggle and want to support me, you can do so by pressing the heart button.</p>
                                <p>Press the calendar to play previous levels and review your history.</p>
                                <br>
                                <p>That's about it! If you have any questions, or have found a bug, let me know by tweeting '<a href="https://twitter.com/IzMichael7" class="hover:underline">@IzMichael7</a>'.</p>
                            </div>
                        </div>
                    {:else if focus == 'stats'}
                        <div id="stats" class="flex flex-col items-center justify-center w-full h-full max-h-full gap-1 p-5 overflow-y-auto">
                            <h1 class="w-full text-3xl font-bold text-center">Statistics - {gamedata.settings.combineStats == true ? 'Seasons 1-5' : 'Season 5'}</h1>
                            <p class="lg:w-4/5 w-full mb-5 text-lg text-center cursor-pointer" on:click={() => focus = 'settings'}>You can choose whether to combine all seasons' stats in the Settings Page.</p>
                            <table class="table">
                                <tr>
                                    <td>TOTAL PLAYED</td>
                                    <td>{gamedata.stats.total.total + (gamedata.settings.combineStats == true ? (gamedataone?.stats.total.total || 0) + (gamedatatwo?.stats.total.total || 0) + (gamedatathree?.stats.total.total || 0) + (gamedatafour?.stats.total.total || 0) : 0)}</td>
                                </tr>
                                <tr>
                                    <td>WIN RATE</td>
                                    <td>{Math.round(((gamedata.stats.total.wins + (gamedata.settings.combineStats == true ? (gamedataone?.stats.total.wins || 0) + (gamedatatwo?.stats.total.wins || 0) + (gamedatathree?.stats.total.wins || 0) + (gamedatafour?.stats.total.wins || 0) : 0)) / (gamedata.stats.total.total + (gamedata.settings.combineStats == true ? (gamedataone?.stats.total.total || 0) + (gamedatatwo?.stats.total.total || 0) + (gamedatathree?.stats.total.total || 0) + (gamedatafour?.stats.total.total || 0) : 0))) * 100)}%</td>
                                </tr>
                                <tr>
                                    <td>CURRENT STREAK</td>
                                    <td>{gamedata.stats.streak.current + (gamedata.settings.combineStats == true ? (gamedataone?.stats.streak.current || 0) + (gamedatatwo?.stats.streak.current || 0) + (gamedatathree?.stats.streak.current || 0) + (gamedatafour?.stats.streak.current || 0) : 0)}</td>
                                </tr>
                                <tr>
                                    <td>BEST STREAK</td>
                                    <td>{gamedata.stats.streak.top + (gamedata.settings.combineStats == true ? (gamedataone?.stats.streak.top || 0) + (gamedatatwo?.stats.streak.top || 0) + (gamedatathree?.stats.streak.top || 0) + (gamedatafour?.stats.streak.top || 0) : 0)}</td>
                                </tr>
                            </table>

                            <h3 class="w-full mt-5 text-xl font-bold text-center">Result Distribution</h3>
                            {#each Object.keys(gamedata.stats.scores) as result, i}
                            <div class="flex flex-row items-center justify-start w-full">
                                <h5 class="mr-2 font-mono text-lg font-bold">{result}</h5>
                                <span class="px-3 pl-1 font-mono text-white text-sm text-right {'bg-' + colors[i]} rounded-full" style="width: {((gamedata.stats.scores[result] + (gamedata.settings.combineStats == true ? (gamedataone?.stats.scores[result] || 0) + (gamedatatwo?.stats.scores[result] || 0) + (gamedatathree?.stats.scores[result] || 0) + (gamedatafour?.stats.scores[result] || 0) : 0)) / (gamedata.stats.total.total + (gamedata.settings.combineStats == true ? (gamedataone?.stats.total.total || 0) + (gamedatatwo?.stats.total.total || 0) + (gamedatathree?.stats.total.total || 0) + (gamedatafour?.stats.total.total || 0) : 0))) * 100}%;">{gamedata.stats.scores[result] + (gamedata.settings.combineStats == true ? (gamedataone?.stats.scores[result] || 0) + (gamedatatwo?.stats.scores[result] || 0) + (gamedatathree?.stats.scores[result] || 0) + (gamedatafour?.stats.scores[result] || 0) : 0)}</span>
                                <h5 class="ml-2 text-lg font-bold {gamedata.stats.scores[result] + (gamedata.settings.combineStats == true ? (gamedataone?.stats.scores[result] || 0) + (gamedatatwo?.stats.scores[result] || 0) + (gamedatathree?.stats.scores[result] || 0) + (gamedatafour?.stats.scores[result] || 0) : 0) < 1 ? 'hidden' : ''}">{Math.round((gamedata.stats.scores[result] + (gamedata.settings.combineStats == true ? (gamedataone?.stats.scores[result] || 0) + (gamedatatwo?.stats.scores[result] || 0) + (gamedatathree?.stats.scores[result] || 0) + (gamedatafour?.stats.scores[result] || 0) : 0)) / (gamedata.stats.total.total + (gamedata.settings.combineStats == true ? (gamedataone?.stats.total.total || 0) + (gamedatatwo?.stats.total.total || 0) + (gamedatathree?.stats.total.total || 0) + (gamedatafour?.stats.total.total || 0) : 0)) * 100)}%</h5>
                            </div>
                            {/each}
                        </div>
                    {:else if focus == 'leaderboard'}
                        <div id="leaderboard" class="flex flex-col items-center justify-center w-full h-full max-h-full gap-1 p-5 overflow-y-auto">
                            <h1 class="w-full text-3xl font-bold text-center">Global Leaderboard for Today</h1>
                            <p class="lg:w-4/5 w-full mb-5 text-lg text-center cursor-pointer" on:click={() => focus = 'settings'}>Not a fan? You can opt-out in the settings menu. Please note that you won't be able to view the leaderboard if you aren't contributing to it.</p>
                            <table class="table">
                                <tr>
                                    <td>TODAY IS</td>
                                    <td>Day #{leaderboard.day}</td>
                                </tr>
                                <tr>
                                    <td>TOTAL PLAYERS</td>
                                    <td>{leaderboard.total}</td>
                                </tr>
                                <tr>
                                    <td>WIN RATE</td>
                                    <td>{Math.round(((leaderboard.totalWon) / (leaderboard.total)) * 100)}%</td>
                                </tr>
                            </table>

                            <h3 class="w-full mt-5 text-xl font-bold text-center">Result Distribution</h3>
                            {#each Object.keys(leaderboard.aggregate) as result, i}
                            <div class="flex flex-row items-center justify-end w-full">
                                <h5 class="mr-2 text-lg font-bold {leaderboard.aggregate[result] < 1 ? 'hidden' : ''}">{Math.round((leaderboard.aggregate[result]) / (leaderboard.total) * 100)}%</h5>
                                <span class="px-3 {leaderboard.aggregate[result] < 1 ? 'pl-1' : ''} font-mono text-white text-sm text-left {'bg-' + colors[i]} rounded-full" style="width: {((leaderboard.aggregate[result]) / (leaderboard.total)) * 100}%;">{leaderboard.aggregate[result]}</span>
                                <h5 class="ml-2 font-mono text-lg font-bold">{result}</h5>
                            </div>
                            {/each}
                        </div>
                    {:else if focus == 'donate'}
                        <div id="donate" class="flex flex-col items-center justify-center w-full h-full max-h-full p-5 overflow-y-auto">
                            <h1 class="w-full text-3xl font-bold text-center">Support Me</h1>
                            <h3 class="lg:w-4/5 mt-3 text-lg text-center">Thanks for playing Flaggle! If you would like to support me, you can tip me at <a href="https://ko-fi.com/IzMichael" class="text-blue-500">ko-fi.com/IzMichael</a>.</h3>
                            <h3 class="lg:w-4/5 mt-3 text-lg text-center">If you already have supported me, thank you. Your support is what makes this all possible. Thank you.</h3>
                            <h3 class="lg:w-4/5 mt-3 text-lg text-center">If you have not supported me, it's okay! Don't feel pressured to; just please enjoy the game and tell your friends!</h3>
                            <!-- <h2 class="mt-3 text-lg font-bold">As a token of my gratitude, supporters get:</h2> -->
                            <!-- <div class="pl-5 ml-5 border-l-2 border-blue-500">
                                <h3 class="text-lg font-bold">Statistics Syncing</h3>
                                <p>Your results and statistics will be backed up to the cloud so that you can play on multiple devices and make sure you don't lose your progress.</p>
                            </div>

                            <h1 class="w-4/5 text-lg text-center">If you have already supported me, enter your email address below and I can verify that you have and grant you your perks.</h1>  -->
                            
                        </div>
                    {:else if focus == 'settings'}
                        <div id="settings" class="flex flex-col items-center justify-center w-full h-full max-h-full p-5 overflow-y-auto">
                            <h1 class="w-full text-3xl font-bold text-center">Settings</h1>
    
                            <!-- <h3 class="w-full mt-5 text-xl font-bold text-center">Date Cycle</h3>
                            <p class="text-lg text-center">What time would you like to get a new flag?</p>
                            <div class="flex flex-row items-center justify-center gap-4 mt-3 whitespace-nowrap min-w-[66.66666%]">
                                <button on:click={() => { gamedata.settings.cycle = 'utc'; gamedata.settings.tz = 'Etc/UTC'}} class="flex-1 p-3 {gamedata.settings.cycle == 'utc' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} transition-colors duration-200 ease-in-out rounded-xl">
                                    <p class="text-center">Midnight UTC</p>
                                    <p class="text-lg font-bold text-center">Universal Time</p>
                                </button>
                                <button on:click={() => { gamedata.settings.cycle = 'local'; gamedata.settings.tz = gamedata.settings.localtz}} class="flex-1 p-3 {gamedata.settings.cycle == 'local' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} transition-colors duration-200 ease-in-out rounded-xl">
                                    <p class="text-center">Midnight Here</p>
                                    <p class="text-lg font-bold text-center">Local Time</p>
                                </button>
                            </div>
                            <p class="text-lg text-center">Your next flag is in {timeToNext}. That's {nextAt.utc().format('HH:mm')} UTC, or {nextAt.format('HH:mm')} Local Time.</p> -->
                        
                            <h3 class="w-full mt-5 text-xl font-bold text-center">Effects and Animations</h3>
                            <div class="flex flex-row items-center justify-between w-full mt-3">
                                <h3 class="text-xl text-left">Enable Global Leaderboard</h3>
                                <Toggle bind:value={gamedata.settings.leaderboard} changeev={() => {
                                    if (gamedata.settings.leaderboard == true) {
                                        plausible('Leaderboard Opt-In');
                                    } else {
                                        plausible('Leaderboard Opt-Out');
                                    };
                                }}></Toggle>
                            </div>
                            <div class="flex flex-row items-center justify-between w-full mt-3">
                                <h3 class="text-xl text-left">Success Rainbow Header</h3>
                                <Toggle bind:value={gamedata.settings.rainbowPill}></Toggle>
                            </div>
                            <div class="flex flex-row items-center justify-between w-full mt-3">
                                <h3 class="text-xl text-left">Success Confetti</h3>
                                <Toggle bind:value={gamedata.settings.confetti}></Toggle>
                            </div>
                            {#if window.localStorage.getItem('flaggle-played') == 'true'}
                            <div class="flex flex-row items-center justify-between w-full mt-3">
                                <h3 class="text-xl text-left">Combine Stats Between Seasons</h3>
                                <Toggle bind:value={gamedata.settings.combineStats}></Toggle>
                            </div>
                            {/if}
                        </div>
                    {:else if focus == 'archive'}
                        <div id="archive" class="flex flex-col items-center justify-center w-full h-full p-5 max-h-[75vh]">
                            <h1 class="sticky top-0 w-full mb-5 text-3xl font-bold text-center">Previous Days ({gamedata.stats.total.total}/{dayjs().diff(dayjs('2024-09-22'), 'day') + 1})</h1>
                            
                            <div class="flex flex-col items-center justify-start w-full pr-2 overflow-y-auto">
                                {#each [...countries].slice(0, dayjs().diff(dayjs('2024-09-22'), 'day') + 1) as day, i}
                                    <div class="flex flex-row items-center justify-between w-full p-1 px-5 overflow-hidden rounded-full h-9 shadow-elevate mb-2 shrink-0 cursor-pointer select-none {gamedata.daily.filter(a => a.day == i+1).length > 0 && gamedata.daily.filter(a => a.day == i+1)[0].result != 'Undecided' ? (gamedata.daily.filter(a => a.day == i+1).length > 0 && gamedata.daily.filter(a => a.day == i+1)[0].result == 'Won' ? 'bg-green-300' : 'bg-red-300') : 'bg-gray-200'} h-9"
                                    on:click={async () => {
                                        await pickDay(i+1);
                                        focus = 'game';
                                    }}>
                                        <img src={day.Flag} alt="Flag of {day.Name}" class="h-full aspect-[2/1]">
                                        <p class="flex-1 pl-2 text-xl text-left">{gamedata.daily.filter(a => a.day == i+1).length > 0 && gamedata.daily.filter(a => a.day == i+1)[0].result == 'Won' ? day.Name : 'Click to play'}</p>
                                        <p class="flex-1 text-xl text-right">{gamedata.daily.filter(a => a.day == i+1).length > 0 && /Won|Lost/.test(gamedata.daily.filter(a => a.day == i+1)[0].result) == true  ? (gamedata.daily.filter(a => a.day == i+1)[0].result == 'Won' ? gamedata.daily.filter(a => a.day == i+1)[0].guesses.length + '/6 Guesses' : 'Lost') : 'Unplayed'}</p>
                                    </div>
                                {/each}
                            </div>
                        </div>
                    {:else if focus == 'imported'}
                        <div id="imported" class="flex flex-col items-center justify-center w-full h-full max-h-full p-5 overflow-y-auto">
                            <h1 class="w-full text-3xl font-bold text-center">Imported Data</h1>
                            <h3 class="lg:w-4/5 mt-3 text-lg text-center">Your data has been successfully imported. If you notice any issues, inconsistencies, or have changed your mind and want to start fresh, feel free to reach out to me on Twitter (<a href="https://twitter.com/izmichael7">@IzMichael7</a>), or email me (<a href="mailto:flaggle@izmichael.com">flaggle@izmichael.com</a>). Thanks for playing!</h3>
                        </div>
                    {:else if focus == 'seasonfive'}
                        <div id="seasonfive" class="flex flex-col items-center justify-center w-full h-full max-h-full p-5 overflow-y-auto">
                            <h1 class="w-full text-3xl font-bold text-center">Welcome to Season Five of Flaggle!</h1>
                            <h3 class="lg:w-4/5 mt-8 text-lg text-center">We've gone through all 252 Flags in the Database! Now that we've done that, we can start again. I've shuffled the list and reset your statistics. Thanks for playing!</h3>
                            
                            <button on:click={async () => window.location = 'https://ko-fi.com/IzMichael'} class="flex-shrink-0 text-white flex flex-row justify-between items-center shadow-elevate font-semibold w-full p-1 px-4 mt-10 bg-[#ff4646] rounded-full h-16 text-center">
                                <img src="/assets/img/icons/heart-white.svg" alt="Heart Icon" class="h-7 w-7 mr-2" />
                                Like Flaggle?<br>Consider making a donation!
                                <img src="/assets/img/icons/kofi.png" alt="Ko-Fi Icon" class="h-7 w-7 ml-2" />
                            </button>
                        </div>
                    {:else if focus == 'addedleaderboard'}
                        <div id="addedleaderboard" class="flex flex-col items-center justify-center w-full h-full max-h-full p-5 overflow-y-auto">
                            <h1 class="w-full text-3xl font-bold text-center">Introducing the Leaderboard!</h1>
                            <h3 class="lg:w-4/5 mt-3 text-lg text-center">I've added a Global Leaderboard, so that you can see how everyone else did today, and how you compare against them.</h3>
                            <h3 class="lg:w-4/5 mt-3 text-lg text-center">You can find this Leaderboard by clicking on the Trophy icon in the top bar.</h3>
                            <div class="lg:w-4/5 flex flex-row items-center justify-center mt-3">
                                <h3 class="pr-5 text-lg text-right">It will look like this --&gt;</h3>
                                <button on:click={() => { focus = 'leaderboard'; gamedata.settings.leaderboard = true; }} class="aspect-square hover:bg-gray-100 w-10 h-10 p-2 transition-colors duration-200 ease-in-out bg-gray-200 rounded-md cursor-pointer">
                                    <img src="/assets/img/icons/trophy.svg" alt="Trophy" class="w-6 h-6" />
                                </button>
                                <h3 class="pl-2 text-base text-right">Psst! Click me...</h3>
                            </div>
                            <h3 class="lg:w-4/5 mt-3 text-lg text-center">Not a fan? You can opt-out in the settings menu. Please note that you won't be able to view the leaderboard if you aren't contributing to it.</h3>
                            <h3 class="lg:w-4/5 mt-3 text-lg text-center">Thanks for playing!</h3>
                        </div>
                    {/if}
                </div></div>
            {/if}
        </div>
    {/if}
</div>

<style global>
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    * {
        @apply font-round outline-none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    #app-wrapper {
        height: 100vh;
    }

    #app-wrapper {
        height: 100svh;
    }

    /* Scrollbar */
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background: #888; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

    /* Buttons */
    .icbtn {
        @apply p-2 rounded-md bg-gray-200 cursor-pointer w-10 h-10 aspect-square transition-colors duration-200 ease-in-out flex-none;
    }
    .icbtn:hover {
        @apply bg-gray-100;
    }

    .icbtn img {
        @apply w-6 h-6;
    }

    button {
        @apply hover:brightness-75 transition-all duration-300 ease-in-out;
    }

    /* Others */

    #tutorial img {
        @apply w-full p-1 my-2 border-2 border-gray-200 rounded-xl flex-none;
    }

    .table {
        @apply overflow-hidden;
    }
    .table td {
        @apply rounded-full w-1/2 p-1 px-3 border-b-4 border-b-white;
    }

    .table tr td:nth-child(1) {
        @apply text-right font-bold capitalize;
    }

    .table tr td:nth-child(2) {
        @apply text-left bg-gray-200;
    }
</style>
